<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div :id="`iiotUploadFileDocker_${index}`" :data-cy="`iiotUploadFileDocker_${index}`">
    <v-form
      :id="`iiotUploadFileDockerForm_${index}`"
      ref="uploadFileDockerForm"
      data-cy="`iiotUploadFileDockerForm_${index}`"
    >
      <v-row>
        <v-col cols="9" class="pa-0 pl-3">
          <div @mouseover="isTooltip($event)">
            <v-tooltip v-if="tooltip" bottom>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-text-field
                    :id="`iiotUploadFileDockerTextField_${index}`"
                    v-model="dockerFile"
                    :placeholder="$t('workloadVersion.dockerComposePlaceholder')"
                    :disabled="!dockerFile"
                    class="ma-0 pa-0 pt-3 textFieldText"
                    readonly
                  />
                </div>
              </template>
              <span v-if="dockerFile">{{ dockerFile }}</span>
              <span v-else> {{ $t('workloadVersion.dockerComposePlaceholder') }} </span>
            </v-tooltip>
            <v-text-field
              v-else
              :id="`iiotUploadFileDockerTextField_${index}`"
              v-model="dockerFile"
              :placeholder="$t('workloadVersion.dockerComposePlaceholder')"
              :disabled="!dockerFile"
              class="ma-0 pa-0 pt-3 textFieldText"
              readonly
            />
          </div>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-btn
            v-if="dockerFile"
            :id="`iiotUploadFileDockerRemoveBtn_${index}`"
            light
            color="primary"
            x-small
            fab
            :disabled="version.released"
            @click="removeUploadedFile()"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon class="white--text" v-on="on"> close </v-icon>
              </template>
              <span>{{ $t('workloadVersion.services.unselectUpload') }}</span>
            </v-tooltip>
          </v-btn>
          <v-btn
            v-else
            :id="`iiotUploadFileDockerBtn_${index}`"
            light
            color="primary"
            x-small
            fab
            :disabled="version.released"
            @click="$refs.uploadFileDockerInput.click()"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon class="white--text" v-on="on"> arrow_upward </v-icon>
              </template>
              <span>{{ $t('workloadVersion.uploadBtnTooltip') }}</span>
            </v-tooltip>
          </v-btn>
          <input
            :id="`iiotUploadFileDockerInput_${index}`"
            ref="uploadFileDockerInput"
            type="file"
            style="display: none"
            :accept="allowedExtensionsForDocker"
            :disabled="version.released"
            @change="importFile"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import shared from '@/helpers/shared';

export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    composeFileChanged: {
      type: Boolean,
    },
    isUpdate: {
      type: Boolean,
    },
  },
  data: () => ({
    allowedExtensionsForDocker: ['.tar.gz', '.tar'],
    dockerFile: '',
    tooltip: true,
  }),
  computed: {
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
    originalFiles() {
      return this.$store.getters['workloads/getOriginalFiles'];
    },
  },
  watch: {
    'version.services': {
      handler() {
        this.$nextTick(() => {
          this.dockerFile = this.tableItem.file ? this.tableItem.file.originalName : '';
        });
      },
      deep: true,
    },
  },
  created() {
    const route = window.location.pathname.split('/');
    this.workloadId = route[route.length - 4];
    if (this.workloadId === 'new') {
      this.dockerFile = '';
      return;
    }
    this.dockerFile = this.tableItem.file ? this.tableItem.file.originalName : '';
  },
  methods: {
    // tooltip will be shown only in the case when text is too big and when contains 3 dots
    isTooltip(event) {
      const input = event.target;
      input.value = input.value || input.placeholder;
      this.tooltip = input.scrollWidth > input.offsetWidth;
    },
    importFile(e) {
      const file = e.target.files[0];
      const fileSize = file ? file.size / 1024 / 1024 : 0;
      if (file && file.name && !this.allowedExtensionsForDocker) {
        this.tableItem.file = {
          source: this.tableItem.dockerFilePath ? `file;${this.tableItem.dockerFilePath}` : 'file',
          originalName: file.name,
          origin: 'upload',
          status: this.tableItem.file.status,
          name: this.tableItem.file.name,
          type: this.tableItem.file.type,
          ...(this.tableItem.file._id && { _id: this.tableItem.file._id }),
        };
        this.version.services.forEach((service) => {
          // eslint-disable-next-line max-len
          if (shared.compareTwoDockerImageNamesAreEqual(service.dockerFilePath, this.tableItem.dockerFilePath)) {
            service.file = this.tableItem.file;
          }
        });
        this.dockerFile = file.name;
        this.$store.dispatch('workloads/save_changed_docker_images', {
          file,
          changedFile: this.tableItem.file,
          imageName: this.tableItem.dockerFilePath,
        });
        this.$store.dispatch('workloads/set_save_enabled');
      } else if (file && file.name && this.allowedExtensionsForDocker) {
        if (fileSize === 0) {
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.emptyFile',
            color: 'red',
            showClose: true,
          });
        } else if (
          fileSize >= 0 &&
          // eslint-disable-next-line max-len
          this.allowedExtensionsForDocker.find((ext) => file.name.toLowerCase().endsWith(ext))
        ) {
          this.tableItem.file = {
            source: this.tableItem.dockerFilePath ? `file;${this.tableItem.dockerFilePath}` : 'file',
            originalName: file.name,
            origin: 'upload',
            status: this.tableItem.file.status,
            name: this.tableItem.file.name,
            type: this.tableItem.file.type,
            ...(this.tableItem.file._id && { _id: this.tableItem.file._id }),
          };
          this.version.services.forEach((service) => {
            // eslint-disable-next-line max-len
            if (shared.compareTwoDockerImageNamesAreEqual(service.dockerFilePath, this.tableItem.dockerFilePath)) {
              service.file = this.tableItem.file;
            }
          });
          this.dockerFile = file.name;
          this.$store.dispatch('workloads/save_changed_docker_images', {
            file,
            changedFile: this.tableItem.file,
            imageName: this.tableItem.dockerFilePath,
          });
          this.$store.dispatch('workloads/set_save_enabled');
        } else {
          const extensionsArray = this.allowedExtensionsForDocker.slice();
          if (extensionsArray.length !== 1) {
            const a =
              extensionsArray[extensionsArray.length - 2] +
              this.$t('uploadFile.or') +
              extensionsArray[extensionsArray.length - 1];
            extensionsArray.splice(-2, 2);
            extensionsArray.push(a);
          }
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.wrongFileExtension',
            textParameters: extensionsArray.join(', '),
            color: 'red',
            showClose: true,
          });
        }
      }
    },
    removeUploadedFile() {
      this.tableItem.dockerFileOption = this.tableItem.dockerFilePath;
      this.dockerFile = '';
      this.tableItem.file.origin = 'docker-repo';
      this.tableItem.file.source = this.tableItem.dockerFilePath;
      this.tableItem.file.originalName = '';
      this.version.services.forEach((service) => {
        // eslint-disable-next-line max-len
        if (shared.compareTwoDockerImageNamesAreEqual(service.dockerFilePath, this.tableItem.dockerFilePath)) {
          service.file = this.tableItem.file;
          service.dockerFileOption = service.dockerFilePath;
          const { username, password } = this.findCredentials(service);
          service.file.username = username;
          service.file.password = password;
          // eslint-disable-next-line max-len
          const oldFile =
            this.originalFiles?.find((originalFile) =>
              shared.compareTwoDockerImageNamesAreEqual(originalFile.serviceName, service.serviceName),
            ) || {};
          const file = oldFile ? oldFile.file : {};
          if (
            this.composeFileChanged ||
            !this.isUpdate ||
            !file ||
            (file.origin === 'docker-repo' &&
              file.username === service.file.username &&
              file.password === service.file.password &&
              file.status === 'available')
          ) {
            this.$store.dispatch('workloads/remove_changed_docker_images', {
              imageName: service.dockerFilePath,
            });
          } else {
            this.$store.dispatch('workloads/save_changed_docker_images', {
              changedFile: service.file,
              imageName: service.dockerFilePath,
            });
          }
        }
        return service;
      });
      this.$store.dispatch('workloads/form_has_changed');
      if (this.version.services.find((service) => service.file.status !== 'available')) {
        this.$store.dispatch('workloads/set_save_enabled');
        return;
      }
      this.$store.dispatch('workloads/set_save_enabled', this.version);
    },
    findCredentials(service) {
      let registry = {};
      const lastSlashIndex = service.dockerFilePath.lastIndexOf('/');
      if (!service.dockerFilePath.includes('/')) {
        registry = this.version.registries.find((reg) => reg.registryName === 'docker.io');
      } else if (
        service.dockerFilePath.includes('/') &&
        !service.dockerFilePath.slice(0, lastSlashIndex).includes('.')
      ) {
        registry = this.version.registries.find(
          (reg) => reg.registryName === `docker.io/${service.dockerFilePath.slice(0, lastSlashIndex)}`,
        );
      } else {
        // eslint-disable-next-line max-len
        registry = this.version.registries.find(
          (reg) => reg.registryName === service.dockerFilePath.slice(0, lastSlashIndex),
        );
      }
      return { username: registry.username, password: registry.password };
    },
  },
};
</script>
<style>
.textFieldText input {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
